.post-type-archive-products,
.page.produtos,
.tax-products-categories {
  .products.section {
    padding-top: $margin * 8;
    background-color: white;
  }

  .products__title {
    color: $light-green;
  }

  .category {
    border: .125rem solid $light-green;
  }
}

body.term-enraizadores .category.term-enraizadores,
body.term-adjuvantes .category.term-adjuvantes,
body.term-formulados .category.term-formulados {
  background-color: $green;
  border: .125rem solid $green;

  .category__title {
    color: $light-green;
  }

  .category__description {
    color: white;
  }
}

.post-type-archive-products,
.tax-products-categories {
  .products__list {
    padding-top: $margin * 2;
    padding-bottom: $margin * 2;
  }

  .list__header {
    width: 100%;
    text-align: center;
    margin-bottom: $margin * 2;
  }

  .list__header .section__title {
    width: 100%;
  }

  .list__title {
    width: 100%;
    font-size: 2.25rem;
    font-weight: 600;
    text-align: center;
    color: $light-green;
  }
}

// Grid
.product.hentry {
  background-color: white;
  margin-bottom: $margin;

  @include for-phone-only {
    &:last-child {
      margin-bottom: 0;
    }
  }

  @include for-tablet-landscape-up {
    lost-column: 1/3 3 $margin;
  }

  .product__thumb {
    padding: $margin;
  }

  .product__title {
    font-size: 1.25rem;
    font-weight: 600;
  }

  .product__title a {
    color: $green;
  }

  .product__caption {
    padding: $margin;
  }
}

.product__excerpt {
  margin-bottom: 0;
}

// Single
.single-products {
  background-color: white;

  .section.product {
    padding-top: $margin * 6;
    padding-bottom: $margin * 2;
    background-color: white;

    @include for-tablet-landscape-up {
      padding-top: $margin * 8;
      padding-bottom: $margin * 4;
    }
  }

  .section.product .col {
    width: 100%;
    margin-bottom: $margin;

    &:last-child {
      margin-bottom: 0;
    }

    @include for-tablet-landscape-up {
      lost-column: 1/2 2 $margin * 2;
      margin-bottom: 0;
    }
  }

  .product__header {
    padding-top: $margin;
    padding-bottom: $margin;
    margin-bottom: $margin;
    position: relative;

    &::after {
      content: "";
      width: 2rem;
      height: .25rem;
      background-color: $light-green;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: .25rem;
    }
  }

  .product__categories {
    list-style: none;
    margin-bottom: 1rem;
  }

  .product__categories li {
    display: inline-block;
    margin-right: 1em;
  }

  .product__categories a {
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .25em;
  }

  .product__title {
    margin-bottom: 1rem;
  }

  .product__description {
    padding-bottom: $margin;
    margin-bottom: $margin;
    position: relative;

    &::after {
      content: "";
      width: 2rem;
      height: .25rem;
      background-color: $light-green;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: .25rem;
    }
  }

  .product__benefits {
    margin-bottom: $margin * 1.5;
  }

  .benefits {
    list-style: none;
  }

  .benefit {
    font-weight: 600;
    padding-left: 1.5rem;
    margin-bottom: .5em;
    position: relative;

    &::before {
      content: "";
      width: .625rem;
      height: .625rem;
      display: inline-block;
      border-radius: .3125rem 0 .3125rem .3125rem;
      background-color: $light-green;
      margin-right: .85rem;
      position: relative;
      margin-left: -1.5rem;
      top: .15em;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  .elements {
    margin-bottom: $margin * 1.5;
  }

  .elements .element {
    min-width: 6.625rem;

    @include for-phone-only {
      min-width: 5.625rem;
    }
  }

  .indications .crop {
    min-width: 5.625rem;

    @include for-phone-only {
      min-width: 5rem;
    }
  }

  .indications .dosage {
    min-width: 10.875rem;

    @include for-phone-only {
      min-width: 9.625rem;
    }
  }

  .indications tbody .crop {
    background-color: rgba($color: white, $alpha: .5);
    color: $green;
    font-weight: 600;
  }

  .indications__ps {
    display: block;
    padding: 1em;
    color: $gray;
    line-height: 1.5em;
  }

  .product__image {
    margin-bottom: $margin;
  }

  .sizes__title {
    width: 100%;
    background-color: $light-green;
    color: $green;
    text-align: center;
    padding: .5rem 1rem;
    margin-bottom: 0;
    border-radius: $radius $radius 0 0;
  }

  .sizes__wrapper {
    padding: $margin;
    background-color: $light-gray;
    border-radius: 0 0 $radius $radius;
  }

  .sizes__loop {
    lost-flex-container: row;
  }

  .sizes__loop .size {
    lost-column: 1/3 3 $margin;
    text-align: center;
  }

  .size__caption {
    margin-top: $margin / 2;
    color: $gray;
    font-weight: 600;
  }

  .quotation.section {
    border-top: $margin * 2 solid white;
    border-bottom: $margin * 2 solid white;
  }
}
