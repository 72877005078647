// Breakpoints
$phone-upper-boundary: 35rem; // 560px
$tablet-portrait-upper-boundary: 56.25rem; // 900px
$tablet-landscape-upper-boundary: 80rem; // 1280px
$desktop-upper-boundary: 103.75rem; // 1660px

@mixin for-phone-only {
  @media (max-width: #{$phone-upper-boundary - 1}) {
    @content;
  }
}

@mixin for-tablet-portrait-up {
  @media (min-width: #{$phone-upper-boundary}) {
    @content;
  }
}

@mixin for-tablet-landscape-up {
  @media (min-width: #{$tablet-portrait-upper-boundary}) {
    @content;
  }
}

@mixin for-desktop-up {
  @media (min-width: #{$tablet-landscape-upper-boundary}) {
    @content;
  }
}

@mixin for-big-desktop-up {
  @media (min-width: #{$desktop-upper-boundary}) {
    @content;
  }
}
