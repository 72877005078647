.quotation-form {
  background-color: white;
  padding-top: $margin * 6;
  padding-bottom: $margin * 2;

  @include for-tablet-landscape-up {
    padding-top: $margin * 8;
    padding-bottom: $margin * 4;
  }

  .col {
    margin-bottom: $margin;
    display: flex;
    flex-flow: row wrap;
    align-content: center;

    &:last-child {
      margin-bottom: 0;
    }

    @include for-tablet-landscape-up {
      lost-column: 1/2 2 $margin * 2;
    }
  }

  .form {
    width: 100%;
  }

  .field__input {
    border: .0625rem solid $gray;
  }
}
