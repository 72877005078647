// Colors
$green: #005640;
$light-green: #76BC21;
$dark-green: #084334;
$transparent-green: rgba(12, 108, 83, .7);
$light-gray: #F8F8F8;
$gray: #848484;

// Margin
$margin: 2rem;

// Radius
$radius: .375rem;

// Grid globals
@lost gutter 2rem;
@lost flexbox flex;
