.single-post {
  background-color: white;

  .post.section {
    padding-top: $margin * 6;
    padding-bottom: $margin * 2;
    background-color: white;

    @include for-tablet-landscape-up {
      padding-top: $margin * 8;
      padding-bottom: $margin * 4;
    }
  }

  .post__title {
    margin-bottom: $margin;
  }

  .content-wrapper {
    display: block;
    width: 100%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto;
  }
}

.blog,
.page-blog {
  .blog.section {
    padding-top: $margin * 6;
    padding-bottom: $margin * 2;

    @include for-tablet-landscape-up {
      padding-top: $margin * 8;
      padding-bottom: $margin * 4;
    }
  }

  .blog__header {
    width: 100%;
    margin-bottom: $margin;
    text-align: center;

    @include for-tablet-landscape-up {
      margin-bottom: $margin * 2;
    }
  }

  .blog__title {
    color: $light-green;
  }

  .blog__posts {
    lost-flex-container: row;
    justify-content: center;
    width: 100%;
  }

  .article {
    margin-bottom: $margin;

    @include for-tablet-landscape-up {
      lost-column: 1/3 3 $margin;
    }
  }
}
