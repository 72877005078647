// Gibson
@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Regular.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Italic.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-SemiBold.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-SemiBold-Italic.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-SemiBold-Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Bold.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Bold-Italic.woff2") format("woff2"),
    url("../fonts/Gibson/Gibson-Bold-Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

// Icons
$icon-font-family: "Icons" !default;
$icon-font-path: "../fonts/Icons" !default;

$icon-down: "\e907";
$icon-left: "\e900";
$icon-right: "\e901";
$icon-up: "\e908";
$icon-menu: "\e902";
$icon-close: "\e903";
$icon-facebook: "\e904";
$icon-instagram: "\e905";
$icon-linkedin: "\e906";

@font-face {
  font-family: '#{$icon-font-family}';
  src: url('#{$icon-font-path}/#{$icon-font-family}.eot?pog90p');
  src: url('#{$icon-font-path}/#{$icon-font-family}.eot?pog90p#iefix') format('embedded-opentype'),
  url('#{$icon-font-path}/#{$icon-font-family}.ttf?pog90p') format('truetype'),
  url('#{$icon-font-path}/#{$icon-font-family}.woff?pog90p') format('woff'),
  url('#{$icon-font-path}/#{$icon-font-family}.svg?pog90p##{$icon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-down::before {
  content: $icon-down;
}

.icon-left::before {
  content: $icon-left;
}

.icon-right::before {
  content: $icon-right;
}

.icon-up::before {
  content: $icon-up;
}

.icon-menu::before {
  content: $icon-menu;
}

.icon-close::before {
  content: $icon-close;
}

.icon-facebook::before {
  content: $icon-facebook;
}

.icon-instagram::before {
  content: $icon-instagram;
}

.icon-linkedin::before {
  content: $icon-linkedin;
}
