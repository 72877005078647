.page.home {

  // Hero
  .hero {
    min-height: 46rem;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $green;
    color: white;
    padding-top: $margin * 4;
    padding-bottom: $margin * 8;
    display: flex;

    @include for-phone-only {
      padding-top: $margin * 7;
    }

    @include for-tablet-portrait-up {
      min-height: 67.5rem;
    }
  }

  .hero .center {
    align-items: flex-start;
    align-content: center;
  }

  .hero .col {

    @include for-tablet-landscape-up {
      lost-column: 1/2;
    }
  }

  .hero h1 {
    font-size: 2.75rem;
    font-weight: 600;

    @include for-phone-only {
      font-size: 2.35rem;
    }
  }

  .hero p {
    color: white;
  }

  // Stores
  .stores {
    padding-top: $margin * 2;
    padding-bottom: $margin * 2;
    position: relative;

    @include for-tablet-landscape-up {
      padding-top: 0;
      padding-bottom: 0;
      height: 50vw;
    }
  }

  .stores .section__title {
    color: $light-green;
  }

  .stores__title {
    font-size: 2.25rem;
    font-weight: 600;
    color: $green;
  }

  .stores__header {

    @include for-tablet-landscape-up {
      lost-column: 1/3 2 $margin;
      position: relative;
      z-index: 1;
      padding-top: 15vw;
    }
  }

  .stores__image {
    width: 100%;
    height: 72vw;
    margin-top: $margin;

    @include for-tablet-landscape-up {
      width: 60vw;
      height: 50vw;
      position: absolute;
      top: -$margin * 2;
      left: 40vw;
      margin-top: 0;
    }
  }

  // Blog
  .blog {
    padding-top: $margin * 2;
    padding-bottom: $margin * 2;

    @include for-tablet-landscape-up {
      padding-top: $margin * 4;
    }
  }

  .blog .section__title {
    color: $light-green;
    text-align: center;
  }

  .blog__header {
    width: 100%;
    margin-bottom: $margin;
  }

  .blog__title {
    font-size: 2.25rem;
    font-weight: 600;
    color: $green;
    text-align: center;
  }

  .blog__text {
    text-align: center;
  }

  .blog__posts {
    lost-flex-container: row;
    justify-content: center;
    width: 100%;
  }

  .blog .col {

    @include for-phone-only {
      margin-bottom: $margin;

      &:last-child {
        margin-bottom: 0;
      }
    }

    @include for-tablet-landscape-up {
      lost-column: 1/3 3 $margin;
    }
  }
}
