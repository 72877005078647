.side-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background-color: rgba($color: $green, $alpha: .9);
  overflow: hidden;
  right: -100%;
  transition: right .4s;
}

.side-menu.is-active {
  right: 0;
}

.side-menu__nav {
  margin: $margin * 4 $margin * 2 0;
  text-align: right;
}

.side-menu__nav li {
  margin-bottom: $margin / 2;

  @include for-tablet-portrait-up {
    margin-bottom: $margin;
  }
}

.side-menu__nav li:last-child {
  margin-bottom: 0;
}

.side-menu__nav a {
  font-size: 1.2em;
  font-weight: 600;
  color: white;
  text-transform: uppercase;

  @include for-tablet-portrait-up {
    font-size: 1.5em;
  }
}

.side-menu__nav a:hover {
  color: $light-green;
}

// Hamburger Button
$hamburger-layer-width: 1.2rem !default;
$hamburger-layer-height: .1875rem !default;
$hamburger-layer-spacing: .25rem !default;
$hamburger-layer-color: $green !default;
$hamburger-layer-border-radius: 0 !default;

.hamburger {
  display: inline-block;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible;
}

.hamburger-box {
  width: $hamburger-layer-width;
  height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: $hamburger-layer-height / -2;

  &,
  &::before,
  &::after {
    content: "";
    display: block;
    width: $hamburger-layer-width;
    height: $hamburger-layer-height;
    background-color: $hamburger-layer-color;
    border-radius: $hamburger-layer-border-radius;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease;
  }
}

.hamburger-inner::before {
  top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
}

.hamburger-inner::after {
  bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
}

.hamburger--spin {

  .hamburger--spin .hamburger-inner {
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(.55, .055, .675, .19);
  }

  .hamburger--spin .hamburger-inner::before {
    transition: top .1s .34s ease-in, opacity .1s ease-in;
  }

  .hamburger--spin .hamburger-inner::after {
    transition: bottom .1s .34s ease-in, transform .3s cubic-bezier(.55, .055, .675, .19);
  }
}

.hamburger--spin.is-active {

  .hamburger-inner {
    transform: rotate(225deg);
    transition-delay: .14s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  .hamburger-inner::before {
    top: 0;
    opacity: 0;
    transition: top .1s ease-out, opacity .1s .14s ease-out;
  }

  .hamburger-inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom .1s ease-out, transform .3s .14s cubic-bezier(.215, .61, .355, 1);
  }
}

.side-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  top: $margin;
  right: $margin;
  z-index: 5;
  background-color: $light-green;
  padding: .45rem .5rem .25rem;
  border: 0;
  outline: none;
  display: block;
  border-radius: 50%;
  box-shadow: 0 .25rem .75rem rgba($color: $green, $alpha: .2);
  transition: background-color .2s;
}

.admin-bar .side-menu-button {
  top: $margin * 2;
}
