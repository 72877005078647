.footer {
  background-color: $green;
  color: white;
  padding-top: $margin * 2;
  padding-bottom: $margin * 2;
  margin-top: $margin * 2;

  @include for-phone-only {
    margin-top: 0;
  }
}

.page.em-breve .footer,
.page.contato .footer,
.page.cotacao .footer,
.post-type-archive-products .footer {
  margin-top: 0;
}

.footer .col {

  @include for-phone-only {
    margin-bottom: $margin;

    &:last-child {
      margin-bottom: 0;
    }
  }

  @include for-tablet-portrait-up {
    lost-column: 1/2;
  }
}

.footer__contacts {
  margin-bottom: $margin;
}

.footer__contacts .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: $light-green;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid $dark-green;
}

.footer__contacts dl {
  line-height: 1.5em;
}

.footer__contacts dd {
  font-size: 1.125rem;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }
}

.footer__logo {
  width: 11.25rem;
}

.footer__logo .logo {
  width: 100%;
}

.footer__logo .coopavel {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}

.footer__footer {
  padding-top: $margin;
  border-top: 0.0625rem solid $dark-green;
}

.footer__footer .social__icons .label {
  font-weight: 600;
  color: white;
  float: left;
  margin-right: 1em;
}

.social__icons .icons__list {
  list-style: none;
}

.social__icons .icons__list li {
  display: inline-block;
  margin-right: 0.5em;

  &:last-child {
    margin-right: 0;
  }
}

.social__icons .icons__list a {
  text-decoration: none;
}

.social__icons .icons__list .icon {
  color: $light-green;
  font-size: 1.25em;
  line-height: 0;
}

.footer__footer .copyright {
  color: $dark-green;
}
