.post-type-archive-crops,
.page.culturas,
.single-crops {
  background-color: white;

  .crops.section {
    padding-top: $margin * 6;
    padding-bottom: $margin * 2;
    background-color: $green;
    position: relative;

    &:after {
      $_size: 4rem;

      content: "";
      width: 0;
      height: 0;
      border-left: $_size * 2 solid transparent;
      border-right: $_size * 2 solid transparent;
      border-top: $_size solid $green;
      display: block;
      position: absolute;
      bottom: -$_size;
      left: 50%;
      margin-left: -$_size * 2;
    }

    @include for-tablet-landscape-up {
      padding-top: $margin * 8;
      padding-bottom: $margin * 4;
    }

    @include for-big-desktop-up {
      padding-top: $margin * 9;
    }
  }

  .crops.section h1,
  .crops.section p {
    width: 100%;
    text-align: center;
    color: white;
  }

  .menu {
    list-style: none;
  }

  // Crop Menu
  .crops__menu {
    width: 100%;
    margin-top: $margin;
  }

  .crops__menu {
    width: 100%;
    text-align: center;
  }

  .crops__menu .menu__item {
    margin-left: $margin / 2;
    margin-right: $margin / 2;
    display: inline-block;
  }

  // Phases
  .phases.section {
    padding-top: $margin * 4;
    padding-bottom: $margin * 2;
    background-color: white;
  }

  .phases__content {
    lost-flex-container: row;

    @include for-phone-only {
      max-width: 22.5rem;
    }

    @include for-tablet-portrait-up {
      max-width: 42rem;
    }

    @include for-tablet-landscape-up {
      max-width: 72rem;
    }

    @include for-big-desktop-up {
      max-width: 103.75rem;
    }
  }

  .phases__content .col {
    width: 100%;

    @include for-tablet-landscape-up {
      lost-column: 1/3 3 $margin * 2;
    }
  }

  .phases__content .phase .image {
    max-width: 6rem;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: $margin;

    @include for-tablet-landscape-up {
      max-width: 10rem;
      margin-bottom: 0;
    }

    @include for-desktop-up {
      max-width: 14rem;
    }
  }

  .phases__content .phase .image__caption {
    margin-top: $margin;
    width: 100%;
    text-align: center;
    color: $gray;
  }

  .phases__content .phase__info {
    lost-align: center;
    flex-direction: column;
  }

  .phases__content .phase__info .phase__crop {
    font-weight: normal;
    font-size: 1.5rem;
  }

  .phases__content .phase__info .phase {
    font-size: 4rem;
    font-weight: normal;
    color: $light-green;
    margin-bottom: 1rem;
    line-height: 1;
  }

  .phases__content .phase__info .indications {
    list-style: none;
  }

  .phases__content .phase__info .indication {
    margin-bottom: .5em;
    text-align: center;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .phases__content .phase__info .indication .product {
    font-size: 1.5rem;
    color: $light-green;
    text-decoration: none;

    &:hover {
      color: $green;
    }
  }

  .phases__menu {
    margin-top: $margin * 2;
    flex-flow: columnn wrap;
  }

  .phases__menu .menu__title,
  .phases__menu .glide__bullets {
    width: 100%;
    text-align: center;
  }

  .phases__menu .menu__title {
    margin-bottom: $margin;
  }

  .phases__menu .glide__bullet {
    display: inline-block;
    margin-left: $margin / 4;
    margin-right: $margin / 4;
    margin-bottom: $margin / 4;
  }
}
