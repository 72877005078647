// Logo
.logo {
  margin-bottom: 0;
}

.logo img {
  width: 100%;
  display: block;
}

// Section
.section__title {
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em;
}

// Article
.article {
  background-color: white;
}

.article__title {
  font-size: 1.25rem;
  font-weight: 600;
}

.article__title a {
  color: $green;
}

.article__caption {
  padding: $margin;
}

// Embed
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

// Image
.image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}

.image-wrapper img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  display: block;
  left: 0;
}

figure img {
  width: 100%;
  height: auto;
}

// Button
.button {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: .75em 1em;
  display: table;
  border-radius: $radius;
  outline: none;
  border: none;
  background-color: transparent;
  transition-duration: .2s;
}

.button--dark {
  background-color: $green;
  color: white;
}

.button--light {
  background-color: $light-green;
  color: $green;
}

.button--rounded {
  $_height: 3rem;

  border: .0625rem solid $light-green;
  color: $light-green;
  background-color: transparent;
  border-radius: $_height;

  &:hover,
  &.active {
    background-color: $light-green;
    color: white;
  }
}

.button.loading {
  &::after {
    content: "";
    width: 2.5rem;
    height: .625rem;
    background-image: url('../images/three-dots.svg');
    background-repeat: no-repeat;
    background-size: cover;
    display: inline-block;
    margin-left: .5em;
  }
}

.button.error {
  background-color: #CC0000;
  color: white;
}


// Form
$_padding: .5em 1em;
$_height: 2.625rem;

.fieldset {
  border: 0;
  padding: 0;
  max-width: 60rem;
}

.field {
  margin-bottom: 1rem;

  &:focus-within label {
    color: $light-green;
  }
}

.row {
  $columns: 12;

  lost-utility: clearfix;

  @for $i from 1 through $columns {

    .field:first-child:nth-last-child(#{$i}),
    .field:first-child:nth-last-child(#{$i})~.field {
      lost-column: 1/#{$i};
    }
  }
}

.field__label {
  display: block;
  margin-bottom: .4rem;
  transition-duration: .2s;

  .required {
    color: $light-green;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1rem;
    position: relative;
    top: .25em;
  }
}

.field__input,
.field__textarea,
.field__select {
  color: $green;
  min-height: $_height;
  background-color: rgba($color: white, $alpha: .6);
  border: none;
  border-radius: $radius;
  padding: $_padding;
  transition-duration: .2s;

  &:focus {
    outline: 0;
    background-color: rgba($color: white, $alpha: 1);
  }
}

::placeholder {
  color: $dark-green;
  opacity: 1;
}

.field__input,
.field__select {
  height: $_height;
}

.field__input,
.field__textarea,
.field__radio {
  width: 100%;
}

.field__textarea {
  resize: vertical;
}

.field__radio {
  padding: .5em 0;
}

.field__radio label {
  margin-left: .25em;
  margin-right: 1em;
}

.field__select {
  display: inline;
  padding-right: $_height;
  appearance: none;
  position: relative;

  &::-ms-expand {
    display: none;
  }
}

.field__select--wrapper {
  display: inline-block;
  position: relative;

  &::after {
    content: "\e909";
    width: $_height - .0625rem;
    height: $_height - (.0625rem * 2);
    font-family: icons !important;
    font-size: 1.2em;
    line-height: $_height;
    text-align: center;
    display: inline-block;
    background-color: rgba($color: white, $alpha: .6);
    color: $gray;
    border-radius: 0 .35em .35em 0;
    position: absolute;
    top: .0625rem;
    right: .0625rem;
    transition-duration: .2s;
    pointer-events: none;
  }

  &:hover::after {
    background-color: rgba($color: white, $alpha: 1);
  }
}

.field__file--wrapper {
  display: inline-block;
  position: relative;
}

.field__file {
  width: .0063rem;
  height: .0063rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.field__file+.file__label {
  height: $_height;
  background-color: white;
  color: $green;
  border-radius: $radius;
  font-weight: 400;
  padding: .5em .875em;
  border-width: .125rem;
  border-style: solid;
  border-color: $green;
  display: inline-block;
  transition-duration: .2s;
  cursor: pointer;
}

.field__file+.file__label * {
  pointer-events: none;
}

.field__file:focus+.file__label,
.field__file+.file__label:hover {
  background-color: $green;
  color: white;
}

.file__label .icon {
  font-size: 1.2em;
  line-height: 1rem;
  margin-right: .4em;
  position: relative;
  top: .15rem;
}

.disabled {
  opacity: .5;
  transition-duration: .2s;
}

// Table
.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: .0625rem;
  background-color: $light-green;
  border-radius: $radius * 1.2;

  @include for-phone-only {
    font-size: .875rem;
  }
}

.table th,
.table td {
  padding: .5em 1em;
  line-height: 1.5em;
}

.table th {
  border: .0625rem solid $light-green;
  background-color: $light-green;
  color: white;
  font-weight: 600;
  text-align: left;
}

.table td {
  color: $gray;
  background-color: white;
}

.table thead th:first-child {
  border-radius: $radius 0 0 0;
}

.table thead th:last-child {
  border-radius: 0 $radius 0 0;
}

.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 $radius;
}

.table tbody tr:last-child td:last-child {
  border-radius: 0 0 $radius 0;
}
