.products.section {
  padding-top: $margin;
  padding-bottom: $margin * 2;
}

.products.section .center {
  height: 100%;
  align-items: flex-start;
  align-content: center;
}

.products.section .products__header {
  width: 100%;
}

.products.section .section__title {
  text-align: center;
}

.products.section .products__title {
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
}

.products.section .products__text {
  text-align: center;
}

.products.section .products__categories {
  margin-top: $margin;
  lost-flex-container: row;
}

.products.section .products__categories .col {
  padding: $margin;
  border-radius: $radius;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: $margin;

  @include for-tablet-landscape-up {
    lost-column: 1/4 4 $margin;
    margin-bottom: 0;
  }
}

.products.section .category__title {
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 3rem;

  &::after {
    content: "";
    width: 2rem;
    height: .25rem;
    background-color: $light-green;
    display: block;
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    margin-left: -1rem;
    border-radius: .25rem;
  }
}

.products.section .category__description {
  display: flex;
  flex-grow: 1;
}

.products.section .category__button {
  margin-left: auto;
  margin-right: auto;
}

.page.home,
.page.sobre {
  .products.section {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $dark-green;
    background-attachment: fixed;
    color: white;
    position: relative;
    padding-bottom: $margin * 2;

    &::after {
      content: "";
      width: 100%;
      height: 20rem;
      display: block;
      position: absolute;
      z-index: 1;
      top: -10rem;
      left: 0;
      background: linear-gradient(0deg,
          rgba(8, 67, 52, 0) 0%,
          rgba(8, 67, 52, 1) 40%,
          rgba(8, 67, 52, 1) 60%,
          rgba(8, 67, 52, 0) 100%);
    }

    @include for-tablet-landscape-up {
      padding-bottom: $margin * 8;
    }
  }

  .products.section .center {
    position: relative;
    z-index: 2;
  }

  .products.section .products__title {
    color: $light-green;
  }

  .products.section .products__text {
    color: white;
  }

  .products.section .products__categories .col {
    background-color: $transparent-green;
  }

  .products.section .category__title {
    color: white;
  }

  .products.section .category__description {
    color: white;
  }
}
