.page.sobre {

  // Section
  .section__title {
    font-size: 1.125rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: 0.25em;
  }

  // Hero
  .hero {
    min-height: 37vw;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $green;
    color: white;
    padding-top: $margin * 10;
    padding-bottom: $margin * 8;
    display: flex;
  }

  .hero .center {
    align-items: flex-start;
    align-content: center;
  }

  .hero .col {
    margin-bottom: $margin;

    @include for-tablet-landscape-up {
      lost-column: 1/2;
      margin-bottom: 0;
    }
  }

  .hero h1 {
    font-size: 2.75rem;
    font-weight: 600;
    width: 100%;
    text-align: center;
  }

  .hero p {
    color: white;
  }

  // About
  .about {
    position: relative;
    background-color: $dark-green;
    color: white;
    padding-top: $margin * 2;
    padding-bottom: $margin * 2;

    &::after {
      content: "";
      width: 100%;
      height: 20rem;
      display: block;
      position: absolute;
      z-index: 1;
      top: -10rem;
      left: 0;
      background: linear-gradient(0deg,
          rgba(8, 67, 52, 0) 0%,
          rgba(8, 67, 52, 1) 40%,
          rgba(8, 67, 52, 1) 60%,
          rgba(8, 67, 52, 0) 100%);
    }
  }

  .about .center {
    height: 100%;
    position: relative;
    z-index: 2;
  }

  .about .col {
    lost-column: 1/2 2 $margin * 4;
    lost-align: center;
  }

  .about p {
    color: white;
  }

  // Quotation
  .quotation {
    margin-top: $margin * 2;
    margin-bottom: $margin * 2;
  }
}
