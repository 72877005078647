.wp-block-buttons .wp-block-button {
  margin-right: $margin / 2;
  margin-bottom: $margin / 2;
}

.wp-block-button__link {
  border-radius: $radius;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: $light-green;
  color: $green;
}

.has-text-color.has-light-green-color {
  color: $light-green;
}

.has-text-color.has-green-color {
  color: $green;
}

.has-text-color.has-dark-green-color {
  color: $dark-green;
}

.has-text-color.has-gray-color {
  color: $gray;
}

.has-text-color.has-white-color {
  color: white;
}

.wp-block-embed {
  display: table;
  margin: $margin auto;
}

#wpadminbar {
  position: fixed !important;
}
