@charset "UTF-8";
@lost gutter 2rem;
@lost flexbox flex;
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
img,
main,
menu,
nav,
section,
summary {
  display: block; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  height: 100%; }

body {
  margin: 0;
  width: 100%;
  overflow-y: scroll; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

b,
optgroup,
strong {
  font-weight: 700; }

dfn {
  font-style: italic; }

h1 {
  font-size: 2em; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -.5em; }

sub {
  bottom: -.25em; }

img {
  border: 0; }

svg:not(:root) {
  overflow: hidden; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre,
textarea {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: searchfield;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid silver;
  padding: .35em .625em .75em; }

legend {
  border: 0;
  padding: 0; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

body:after,
body:before {
  content: "";
  display: table; }

*,
:after,
:before {
  box-sizing: border-box;
  margin: 0;
  padding: 0; }

fieldset,
figure,
h1 {
  margin: 0; }

audio,
embed,
iframe,
img,
input,
object,
video {
  max-width: 100%; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Regular.woff2") format("woff2"), url("../fonts/Gibson/Gibson-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Italic.woff2") format("woff2"), url("../fonts/Gibson/Gibson-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-SemiBold.woff2") format("woff2"), url("../fonts/Gibson/Gibson-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-SemiBold-Italic.woff2") format("woff2"), url("../fonts/Gibson/Gibson-SemiBold-Italic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Bold.woff2") format("woff2"), url("../fonts/Gibson/Gibson-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap; }

@font-face {
  font-family: "Gibson";
  src: url("../fonts/Gibson/Gibson-Bold-Italic.woff2") format("woff2"), url("../fonts/Gibson/Gibson-Bold-Italic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap; }

@font-face {
  font-family: "Icons";
  src: url("../fonts/Icons/Icons.eot?pog90p");
  src: url("../fonts/Icons/Icons.eot?pog90p#iefix") format("embedded-opentype"), url("../fonts/Icons/Icons.ttf?pog90p") format("truetype"), url("../fonts/Icons/Icons.woff?pog90p") format("woff"), url("../fonts/Icons/Icons.svg?pog90p#Icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "Icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-down::before {
  content: ""; }

.icon-left::before {
  content: ""; }

.icon-right::before {
  content: ""; }

.icon-up::before {
  content: ""; }

.icon-menu::before {
  content: ""; }

.icon-close::before {
  content: ""; }

.icon-facebook::before {
  content: ""; }

.icon-instagram::before {
  content: ""; }

.icon-linkedin::before {
  content: ""; }

.wp-block-buttons .wp-block-button {
  margin-right: 1rem;
  margin-bottom: 1rem; }

.wp-block-button__link {
  border-radius: 0.375rem;
  text-transform: uppercase;
  font-size: 1rem;
  background-color: #76BC21;
  color: #005640; }

.has-text-color.has-light-green-color {
  color: #76BC21; }

.has-text-color.has-green-color {
  color: #005640; }

.has-text-color.has-dark-green-color {
  color: #084334; }

.has-text-color.has-gray-color {
  color: #848484; }

.has-text-color.has-white-color {
  color: white; }

.wp-block-embed {
  display: table;
  margin: 2rem auto; }

#wpadminbar {
  position: fixed !important; }

body {
  font-family: "Gibson", sans-serif;
  font-size: 1em;
  font-weight: normal;
  color: #005640;
  background-color: #F8F8F8;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

*::selection {
  background: #005640;
  color: white; }

.no-scroll {
  overflow: hidden; }

p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
  color: #848484; }
  p:last-child {
    margin-bottom: 0; }

p a {
  font-weight: 600;
  color: #005640; }
  p a:hover {
    color: #76BC21; }

strong {
  font-weight: 600; }

dt {
  font-weight: 600; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  font-weight: 600; }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    text-decoration: none; }
    h1 a:hover,
    h2 a:hover,
    h3 a:hover,
    h4 a:hover,
    h5 a:hover,
    h6 a:hover {
      color: #76BC21; }

ul,
ol {
  list-style-position: inside;
  line-height: 1.5em; }

a {
  color: #005640;
  transition: color 0.2s; }

a.link {
  font-weight: 600;
  text-decoration: none;
  line-height: 1rem; }

nav ul {
  list-style: none; }

nav a {
  text-decoration: none; }

button {
  outline: none; }

address {
  font-style: normal;
  line-height: 1.5em; }

img {
  height: auto; }

.app {
  position: relative;
  z-index: 1;
  overflow-x: hidden; }

.content {
  min-height: 26rem; }
  @media (min-width: 35rem) {
    .content {
      min-height: 36rem; } }

.center {
  width: 100%;
  lost-utility: clearfix; }
  @media (max-width: 34rem) {
    .center {
      lost-center: 22.5rem; } }
  @media (min-width: 35rem) {
    .center {
      lost-center: 42rem; } }
  @media (min-width: 56.25rem) {
    .center {
      lost-center: 72rem; } }
  @media (min-width: 103.75rem) {
    .center {
      lost-center: 103.75rem; } }

.logo {
  margin-bottom: 0; }

.logo img {
  width: 100%;
  display: block; }

.section__title {
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em; }

.article {
  background-color: white; }

.article__title {
  font-size: 1.25rem;
  font-weight: 600; }

.article__title a {
  color: #005640; }

.article__caption {
  padding: 2rem; }

.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
  max-width: 100%;
  height: auto; }

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.image-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

.image-wrapper img {
  width: 100%;
  height: auto;
  position: absolute;
  top: 0;
  display: block;
  left: 0; }

figure img {
  width: 100%;
  height: auto; }

.button {
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  padding: .75em 1em;
  display: table;
  border-radius: 0.375rem;
  outline: none;
  border: none;
  background-color: transparent;
  transition-duration: .2s; }

.button--dark {
  background-color: #005640;
  color: white; }

.button--light {
  background-color: #76BC21;
  color: #005640; }

.button--rounded {
  border: 0.0625rem solid #76BC21;
  color: #76BC21;
  background-color: transparent;
  border-radius: 3rem; }
  .button--rounded:hover, .button--rounded.active {
    background-color: #76BC21;
    color: white; }

.button.loading::after {
  content: "";
  width: 2.5rem;
  height: .625rem;
  background-image: url("../images/three-dots.svg");
  background-repeat: no-repeat;
  background-size: cover;
  display: inline-block;
  margin-left: .5em; }

.button.error {
  background-color: #CC0000;
  color: white; }

.fieldset {
  border: 0;
  padding: 0;
  max-width: 60rem; }

.field {
  margin-bottom: 1rem; }
  .field:focus-within label {
    color: #76BC21; }

.row {
  lost-utility: clearfix; }
  .row .field:first-child:nth-last-child(1),
  .row .field:first-child:nth-last-child(1) ~ .field {
    lost-column: 1/1; }
  .row .field:first-child:nth-last-child(2),
  .row .field:first-child:nth-last-child(2) ~ .field {
    lost-column: 1/2; }
  .row .field:first-child:nth-last-child(3),
  .row .field:first-child:nth-last-child(3) ~ .field {
    lost-column: 1/3; }
  .row .field:first-child:nth-last-child(4),
  .row .field:first-child:nth-last-child(4) ~ .field {
    lost-column: 1/4; }
  .row .field:first-child:nth-last-child(5),
  .row .field:first-child:nth-last-child(5) ~ .field {
    lost-column: 1/5; }
  .row .field:first-child:nth-last-child(6),
  .row .field:first-child:nth-last-child(6) ~ .field {
    lost-column: 1/6; }
  .row .field:first-child:nth-last-child(7),
  .row .field:first-child:nth-last-child(7) ~ .field {
    lost-column: 1/7; }
  .row .field:first-child:nth-last-child(8),
  .row .field:first-child:nth-last-child(8) ~ .field {
    lost-column: 1/8; }
  .row .field:first-child:nth-last-child(9),
  .row .field:first-child:nth-last-child(9) ~ .field {
    lost-column: 1/9; }
  .row .field:first-child:nth-last-child(10),
  .row .field:first-child:nth-last-child(10) ~ .field {
    lost-column: 1/10; }
  .row .field:first-child:nth-last-child(11),
  .row .field:first-child:nth-last-child(11) ~ .field {
    lost-column: 1/11; }
  .row .field:first-child:nth-last-child(12),
  .row .field:first-child:nth-last-child(12) ~ .field {
    lost-column: 1/12; }

.field__label {
  display: block;
  margin-bottom: .4rem;
  transition-duration: .2s; }
  .field__label .required {
    color: #76BC21;
    font-size: 1.2em;
    font-weight: 700;
    line-height: 1rem;
    position: relative;
    top: .25em; }

.field__input,
.field__textarea,
.field__select {
  color: #005640;
  min-height: 2.625rem;
  background-color: rgba(255, 255, 255, 0.6);
  border: none;
  border-radius: 0.375rem;
  padding: 0.5em 1em;
  transition-duration: .2s; }
  .field__input:focus,
  .field__textarea:focus,
  .field__select:focus {
    outline: 0;
    background-color: white; }

::placeholder {
  color: #084334;
  opacity: 1; }

.field__input,
.field__select {
  height: 2.625rem; }

.field__input,
.field__textarea,
.field__radio {
  width: 100%; }

.field__textarea {
  resize: vertical; }

.field__radio {
  padding: .5em 0; }

.field__radio label {
  margin-left: .25em;
  margin-right: 1em; }

.field__select {
  display: inline;
  padding-right: 2.625rem;
  appearance: none;
  position: relative; }
  .field__select::-ms-expand {
    display: none; }

.field__select--wrapper {
  display: inline-block;
  position: relative; }
  .field__select--wrapper::after {
    content: "\e909";
    width: 2.5625rem;
    height: 2.5rem;
    font-family: icons !important;
    font-size: 1.2em;
    line-height: 2.625rem;
    text-align: center;
    display: inline-block;
    background-color: rgba(255, 255, 255, 0.6);
    color: #848484;
    border-radius: 0 .35em .35em 0;
    position: absolute;
    top: .0625rem;
    right: .0625rem;
    transition-duration: .2s;
    pointer-events: none; }
  .field__select--wrapper:hover::after {
    background-color: white; }

.field__file--wrapper {
  display: inline-block;
  position: relative; }

.field__file {
  width: .0063rem;
  height: .0063rem;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1; }

.field__file + .file__label {
  height: 2.625rem;
  background-color: white;
  color: #005640;
  border-radius: 0.375rem;
  font-weight: 400;
  padding: .5em .875em;
  border-width: .125rem;
  border-style: solid;
  border-color: #005640;
  display: inline-block;
  transition-duration: .2s;
  cursor: pointer; }

.field__file + .file__label * {
  pointer-events: none; }

.field__file:focus + .file__label,
.field__file + .file__label:hover {
  background-color: #005640;
  color: white; }

.file__label .icon {
  font-size: 1.2em;
  line-height: 1rem;
  margin-right: .4em;
  position: relative;
  top: .15rem; }

.disabled {
  opacity: .5;
  transition-duration: .2s; }

.table {
  width: 100%;
  border-collapse: separate;
  border-spacing: .0625rem;
  background-color: #76BC21;
  border-radius: 0.45rem; }
  @media (max-width: 34rem) {
    .table {
      font-size: .875rem; } }

.table th,
.table td {
  padding: .5em 1em;
  line-height: 1.5em; }

.table th {
  border: 0.0625rem solid #76BC21;
  background-color: #76BC21;
  color: white;
  font-weight: 600;
  text-align: left; }

.table td {
  color: #848484;
  background-color: white; }

.table thead th:first-child {
  border-radius: 0.375rem 0 0 0; }

.table thead th:last-child {
  border-radius: 0 0.375rem 0 0; }

.table tbody tr:last-child td:first-child {
  border-radius: 0 0 0 0.375rem; }

.table tbody tr:last-child td:last-child {
  border-radius: 0 0 0.375rem 0; }

.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding-top: 2rem;
  padding-bottom: 2rem; }

@media (max-width: 34rem) {
  .header__logo {
    width: 70%; } }

@media (min-width: 35rem) {
  .header__logo {
    lost-column: 1/2 2 2rem; } }

@media (min-width: 56.25rem) {
  .header__logo {
    lost-column: 1/3 2 2rem; } }

.header__logo .wrapper {
  width: 100%;
  max-width: 20rem; }

.header__logo .logo {
  width: 100%; }

.header__logo .coopavel {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%; }

.header__nav {
  text-align: right; }
  @media (min-width: 35rem) {
    .header__nav {
      lost-column: 1/2 2 2rem; } }
  @media (min-width: 56.25rem) {
    .header__nav {
      lost-column: 2/3 2 2rem; } }

.header__nav .menu {
  margin-top: 3.5em; }

.header__nav .menu > .menu-item {
  display: inline-block;
  margin-left: 2rem;
  padding-bottom: .5em; }

.header__nav a {
  font-size: 1.5rem;
  font-weight: 600;
  color: #005640; }
  .header__nav a:hover {
    color: #76BC21; }

.page.home .header__nav a,
.page.sobre .header__nav a,
.post-type-archive-crops .header__nav a,
.single-crops .header__nav a,
.page.culturas .header__nav a,
.page.contato .header__nav a {
  color: white; }

.header__nav .menu-item-has-children {
  position: relative; }

.header__nav .menu-item-has-children > a::after {
  content: "";
  font-family: "Icons";
  line-height: 0;
  color: #76BC21;
  margin-left: .25em;
  position: relative;
  top: .085em;
  transition: color .2s; }

.header__nav .sub-menu {
  background-color: white;
  position: absolute;
  top: 2em;
  right: 0;
  text-align: left;
  padding-top: .5em;
  padding-bottom: .5em;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s;
  border-radius: 0.375rem 0 0.375rem 0.375rem;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 0, 0, 0.2); }

.header__nav .menu-item-has-children:hover .sub-menu {
  visibility: visible;
  opacity: 1; }

.header__nav .menu-item-has-children:hover > a::after {
  color: white; }

.header__nav .sub-menu li {
  display: block;
  padding-bottom: 0; }
  .header__nav .sub-menu li:last-child {
    margin-bottom: 0; }
  @media (min-width: 80rem) {
    .header__nav .sub-menu li {
      margin-left: 0; } }

.header__nav .sub-menu a {
  background-color: white;
  color: #848484;
  white-space: nowrap;
  padding: .25em 1em;
  display: block;
  margin-top: 0; }
  .header__nav .sub-menu a:hover {
    color: #76BC21; }

.footer {
  background-color: #005640;
  color: white;
  padding-top: 4rem;
  padding-bottom: 4rem;
  margin-top: 4rem; }
  @media (max-width: 34rem) {
    .footer {
      margin-top: 0; } }

.page.em-breve .footer,
.page.contato .footer,
.page.cotacao .footer,
.post-type-archive-products .footer {
  margin-top: 0; }

@media (max-width: 34rem) {
  .footer .col {
    margin-bottom: 2rem; }
    .footer .col:last-child {
      margin-bottom: 0; } }

@media (min-width: 35rem) {
  .footer .col {
    lost-column: 1/2; } }

.footer__contacts {
  margin-bottom: 2rem; }

.footer__contacts .title {
  font-size: 1.125rem;
  font-weight: 600;
  color: #76BC21;
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid #084334; }

.footer__contacts dl {
  line-height: 1.5em; }

.footer__contacts dd {
  font-size: 1.125rem;
  margin-bottom: 1rem; }
  .footer__contacts dd:last-child {
    margin-bottom: 0; }

.footer__logo {
  width: 11.25rem; }

.footer__logo .logo {
  width: 100%; }

.footer__logo .coopavel {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%; }

.footer__footer {
  padding-top: 2rem;
  border-top: 0.0625rem solid #084334; }

.footer__footer .social__icons .label {
  font-weight: 600;
  color: white;
  float: left;
  margin-right: 1em; }

.social__icons .icons__list {
  list-style: none; }

.social__icons .icons__list li {
  display: inline-block;
  margin-right: 0.5em; }
  .social__icons .icons__list li:last-child {
    margin-right: 0; }

.social__icons .icons__list a {
  text-decoration: none; }

.social__icons .icons__list .icon {
  color: #76BC21;
  font-size: 1.25em;
  line-height: 0; }

.footer__footer .copyright {
  color: #084334; }

.side-menu {
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 3;
  background-color: rgba(0, 86, 64, 0.9);
  overflow: hidden;
  right: -100%;
  transition: right .4s; }

.side-menu.is-active {
  right: 0; }

.side-menu__nav {
  margin: 8rem 4rem 0;
  text-align: right; }

.side-menu__nav li {
  margin-bottom: 1rem; }
  @media (min-width: 35rem) {
    .side-menu__nav li {
      margin-bottom: 2rem; } }

.side-menu__nav li:last-child {
  margin-bottom: 0; }

.side-menu__nav a {
  font-size: 1.2em;
  font-weight: 600;
  color: white;
  text-transform: uppercase; }
  @media (min-width: 35rem) {
    .side-menu__nav a {
      font-size: 1.5em; } }

.side-menu__nav a:hover {
  color: #76BC21; }

.hamburger {
  display: inline-block;
  cursor: pointer;
  font: inherit;
  color: inherit;
  text-transform: none;
  margin: 0;
  overflow: visible; }

.hamburger-box {
  width: 1.2rem;
  height: 1.0625rem;
  display: inline-block;
  position: relative; }

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -0.09375rem; }
  .hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
    content: "";
    display: block;
    width: 1.2rem;
    height: 0.1875rem;
    background-color: #005640;
    border-radius: 0;
    position: absolute;
    transition-property: transform;
    transition-duration: .15s;
    transition-timing-function: ease; }

.hamburger-inner::before {
  top: -0.4375rem; }

.hamburger-inner::after {
  bottom: -0.4375rem; }

.hamburger--spin .hamburger--spin .hamburger-inner {
  transition-duration: .3s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin .hamburger--spin .hamburger-inner::before {
  transition: top .1s .34s ease-in, opacity .1s ease-in; }

.hamburger--spin .hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.34s ease-in, transform 0.3s cubic-bezier(0.55, 0.055, 0.675, 0.19); }

.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: .14s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }

.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top .1s ease-out, opacity .1s .14s ease-out; }

.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.3s 0.14s cubic-bezier(0.215, 0.61, 0.355, 1); }

.side-menu-button {
  width: 2.5rem;
  height: 2.5rem;
  position: fixed;
  top: 2rem;
  right: 2rem;
  z-index: 5;
  background-color: #76BC21;
  padding: .45rem .5rem .25rem;
  border: 0;
  outline: none;
  display: block;
  border-radius: 50%;
  box-shadow: 0 0.25rem 0.75rem rgba(0, 86, 64, 0.2);
  transition: background-color .2s; }

.admin-bar .side-menu-button {
  top: 4rem; }

.products.section {
  padding-top: 2rem;
  padding-bottom: 4rem; }

.products.section .center {
  height: 100%;
  align-items: flex-start;
  align-content: center; }

.products.section .products__header {
  width: 100%; }

.products.section .section__title {
  text-align: center; }

.products.section .products__title {
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center; }

.products.section .products__text {
  text-align: center; }

.products.section .products__categories {
  margin-top: 2rem;
  lost-flex-container: row; }

.products.section .products__categories .col {
  padding: 2rem;
  border-radius: 0.375rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem; }
  @media (min-width: 56.25rem) {
    .products.section .products__categories .col {
      lost-column: 1/4 4 2rem;
      margin-bottom: 0; } }

.products.section .category__title {
  font-size: 1.5rem;
  font-weight: 600;
  position: relative;
  margin-bottom: 3rem; }
  .products.section .category__title::after {
    content: "";
    width: 2rem;
    height: .25rem;
    background-color: #76BC21;
    display: block;
    position: absolute;
    bottom: -1.5rem;
    left: 50%;
    margin-left: -1rem;
    border-radius: .25rem; }

.products.section .category__description {
  display: flex;
  flex-grow: 1; }

.products.section .category__button {
  margin-left: auto;
  margin-right: auto; }

.page.home .products.section,
.page.sobre .products.section {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #084334;
  background-attachment: fixed;
  color: white;
  position: relative;
  padding-bottom: 4rem; }
  .page.home .products.section::after,
  .page.sobre .products.section::after {
    content: "";
    width: 100%;
    height: 20rem;
    display: block;
    position: absolute;
    z-index: 1;
    top: -10rem;
    left: 0;
    background: linear-gradient(0deg, rgba(8, 67, 52, 0) 0%, #084334 40%, #084334 60%, rgba(8, 67, 52, 0) 100%); }
  @media (min-width: 56.25rem) {
    .page.home .products.section,
    .page.sobre .products.section {
      padding-bottom: 16rem; } }

.page.home .products.section .center,
.page.sobre .products.section .center {
  position: relative;
  z-index: 2; }

.page.home .products.section .products__title,
.page.sobre .products.section .products__title {
  color: #76BC21; }

.page.home .products.section .products__text,
.page.sobre .products.section .products__text {
  color: white; }

.page.home .products.section .products__categories .col,
.page.sobre .products.section .products__categories .col {
  background-color: rgba(12, 108, 83, 0.7); }

.page.home .products.section .category__title,
.page.sobre .products.section .category__title {
  color: white; }

.page.home .products.section .category__description,
.page.sobre .products.section .category__description {
  color: white; }

.quotation.section {
  background-color: #76BC21;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media (min-width: 56.25rem) {
    .quotation.section {
      height: 36vw;
      position: relative;
      border-top: 4rem solid #F8F8F8;
      border-bottom: 4rem solid #F8F8F8;
      padding-top: 0;
      padding-bottom: 0; } }

.single-post .quotation.section {
  border-top: 4rem solid white;
  border-bottom: 4rem solid white; }

.page.em-breve .quotation.section {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.quotation.section .center {
  height: 100%; }

.quotation.section .section__title {
  color: #005640; }

.quotation.section .quotation__title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #005640; }

.quotation.section .col {
  margin-bottom: 2rem; }
  .quotation.section .col:last-child {
    margin-bottom: 0; }
  @media (min-width: 56.25rem) {
    .quotation.section .col {
      lost-column: 1/2 2 0; } }

@media (min-width: 56.25rem) {
  .quotation.section .quotation__header {
    lost-move: 1/2;
    padding: 4rem;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-content: center; } }

.quotation.section .quotation__title {
  color: white; }

.quotation.section .quotation__text {
  color: white; }

.quotation.section .quotation__image {
  width: 100%;
  height: 64vw; }
  @media (min-width: 56.25rem) {
    .quotation.section .quotation__image {
      lost-move: -1/2;
      width: 50vw;
      height: 36vw;
      position: absolute;
      top: -4rem;
      left: 0; } }

.page.home .hero {
  min-height: 46rem;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #005640;
  color: white;
  padding-top: 8rem;
  padding-bottom: 16rem;
  display: flex; }
  @media (max-width: 34rem) {
    .page.home .hero {
      padding-top: 14rem; } }
  @media (min-width: 35rem) {
    .page.home .hero {
      min-height: 67.5rem; } }

.page.home .hero .center {
  align-items: flex-start;
  align-content: center; }

@media (min-width: 56.25rem) {
  .page.home .hero .col {
    lost-column: 1/2; } }

.page.home .hero h1 {
  font-size: 2.75rem;
  font-weight: 600; }
  @media (max-width: 34rem) {
    .page.home .hero h1 {
      font-size: 2.35rem; } }

.page.home .hero p {
  color: white; }

.page.home .stores {
  padding-top: 4rem;
  padding-bottom: 4rem;
  position: relative; }
  @media (min-width: 56.25rem) {
    .page.home .stores {
      padding-top: 0;
      padding-bottom: 0;
      height: 50vw; } }

.page.home .stores .section__title {
  color: #76BC21; }

.page.home .stores__title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #005640; }

@media (min-width: 56.25rem) {
  .page.home .stores__header {
    lost-column: 1/3 2 2rem;
    position: relative;
    z-index: 1;
    padding-top: 15vw; } }

.page.home .stores__image {
  width: 100%;
  height: 72vw;
  margin-top: 2rem; }
  @media (min-width: 56.25rem) {
    .page.home .stores__image {
      width: 60vw;
      height: 50vw;
      position: absolute;
      top: -4rem;
      left: 40vw;
      margin-top: 0; } }

.page.home .blog {
  padding-top: 4rem;
  padding-bottom: 4rem; }
  @media (min-width: 56.25rem) {
    .page.home .blog {
      padding-top: 8rem; } }

.page.home .blog .section__title {
  color: #76BC21;
  text-align: center; }

.page.home .blog__header {
  width: 100%;
  margin-bottom: 2rem; }

.page.home .blog__title {
  font-size: 2.25rem;
  font-weight: 600;
  color: #005640;
  text-align: center; }

.page.home .blog__text {
  text-align: center; }

.page.home .blog__posts {
  lost-flex-container: row;
  justify-content: center;
  width: 100%; }

@media (max-width: 34rem) {
  .page.home .blog .col {
    margin-bottom: 2rem; }
    .page.home .blog .col:last-child {
      margin-bottom: 0; } }

@media (min-width: 56.25rem) {
  .page.home .blog .col {
    lost-column: 1/3 3 2rem; } }

.page.sobre .section__title {
  font-size: 1.125rem;
  font-weight: normal;
  text-transform: uppercase;
  letter-spacing: 0.25em; }

.page.sobre .hero {
  min-height: 37vw;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #005640;
  color: white;
  padding-top: 20rem;
  padding-bottom: 16rem;
  display: flex; }

.page.sobre .hero .center {
  align-items: flex-start;
  align-content: center; }

.page.sobre .hero .col {
  margin-bottom: 2rem; }
  @media (min-width: 56.25rem) {
    .page.sobre .hero .col {
      lost-column: 1/2;
      margin-bottom: 0; } }

.page.sobre .hero h1 {
  font-size: 2.75rem;
  font-weight: 600;
  width: 100%;
  text-align: center; }

.page.sobre .hero p {
  color: white; }

.page.sobre .about {
  position: relative;
  background-color: #084334;
  color: white;
  padding-top: 4rem;
  padding-bottom: 4rem; }
  .page.sobre .about::after {
    content: "";
    width: 100%;
    height: 20rem;
    display: block;
    position: absolute;
    z-index: 1;
    top: -10rem;
    left: 0;
    background: linear-gradient(0deg, rgba(8, 67, 52, 0) 0%, #084334 40%, #084334 60%, rgba(8, 67, 52, 0) 100%); }

.page.sobre .about .center {
  height: 100%;
  position: relative;
  z-index: 2; }

.page.sobre .about .col {
  lost-column: 1/2 2 8rem;
  lost-align: center; }

.page.sobre .about p {
  color: white; }

.page.sobre .quotation {
  margin-top: 4rem;
  margin-bottom: 4rem; }

.post-type-archive-products .products.section,
.page.produtos .products.section,
.tax-products-categories .products.section {
  padding-top: 16rem;
  background-color: white; }

.post-type-archive-products .products__title,
.page.produtos .products__title,
.tax-products-categories .products__title {
  color: #76BC21; }

.post-type-archive-products .category,
.page.produtos .category,
.tax-products-categories .category {
  border: 0.125rem solid #76BC21; }

body.term-enraizadores .category.term-enraizadores,
body.term-adjuvantes .category.term-adjuvantes,
body.term-formulados .category.term-formulados {
  background-color: #005640;
  border: 0.125rem solid #005640; }
  body.term-enraizadores .category.term-enraizadores .category__title,
  body.term-adjuvantes .category.term-adjuvantes .category__title,
  body.term-formulados .category.term-formulados .category__title {
    color: #76BC21; }
  body.term-enraizadores .category.term-enraizadores .category__description,
  body.term-adjuvantes .category.term-adjuvantes .category__description,
  body.term-formulados .category.term-formulados .category__description {
    color: white; }

.post-type-archive-products .products__list,
.tax-products-categories .products__list {
  padding-top: 4rem;
  padding-bottom: 4rem; }

.post-type-archive-products .list__header,
.tax-products-categories .list__header {
  width: 100%;
  text-align: center;
  margin-bottom: 4rem; }

.post-type-archive-products .list__header .section__title,
.tax-products-categories .list__header .section__title {
  width: 100%; }

.post-type-archive-products .list__title,
.tax-products-categories .list__title {
  width: 100%;
  font-size: 2.25rem;
  font-weight: 600;
  text-align: center;
  color: #76BC21; }

.product.hentry {
  background-color: white;
  margin-bottom: 2rem; }
  @media (max-width: 34rem) {
    .product.hentry:last-child {
      margin-bottom: 0; } }
  @media (min-width: 56.25rem) {
    .product.hentry {
      lost-column: 1/3 3 2rem; } }
  .product.hentry .product__thumb {
    padding: 2rem; }
  .product.hentry .product__title {
    font-size: 1.25rem;
    font-weight: 600; }
  .product.hentry .product__title a {
    color: #005640; }
  .product.hentry .product__caption {
    padding: 2rem; }

.product__excerpt {
  margin-bottom: 0; }

.single-products {
  background-color: white; }
  .single-products .section.product {
    padding-top: 12rem;
    padding-bottom: 4rem;
    background-color: white; }
    @media (min-width: 56.25rem) {
      .single-products .section.product {
        padding-top: 16rem;
        padding-bottom: 8rem; } }
  .single-products .section.product .col {
    width: 100%;
    margin-bottom: 2rem; }
    .single-products .section.product .col:last-child {
      margin-bottom: 0; }
    @media (min-width: 56.25rem) {
      .single-products .section.product .col {
        lost-column: 1/2 2 4rem;
        margin-bottom: 0; } }
  .single-products .product__header {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative; }
    .single-products .product__header::after {
      content: "";
      width: 2rem;
      height: .25rem;
      background-color: #76BC21;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: .25rem; }
  .single-products .product__categories {
    list-style: none;
    margin-bottom: 1rem; }
  .single-products .product__categories li {
    display: inline-block;
    margin-right: 1em; }
  .single-products .product__categories a {
    text-decoration: none;
    font-size: 1.125rem;
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: .25em; }
  .single-products .product__title {
    margin-bottom: 1rem; }
  .single-products .product__description {
    padding-bottom: 2rem;
    margin-bottom: 2rem;
    position: relative; }
    .single-products .product__description::after {
      content: "";
      width: 2rem;
      height: .25rem;
      background-color: #76BC21;
      display: block;
      position: absolute;
      bottom: 0;
      left: 0;
      border-radius: .25rem; }
  .single-products .product__benefits {
    margin-bottom: 3rem; }
  .single-products .benefits {
    list-style: none; }
  .single-products .benefit {
    font-weight: 600;
    padding-left: 1.5rem;
    margin-bottom: .5em;
    position: relative; }
    .single-products .benefit::before {
      content: "";
      width: .625rem;
      height: .625rem;
      display: inline-block;
      border-radius: .3125rem 0 .3125rem .3125rem;
      background-color: #76BC21;
      margin-right: .85rem;
      position: relative;
      margin-left: -1.5rem;
      top: .15em; }
    .single-products .benefit:last-child {
      margin-bottom: 0; }
  .single-products .elements {
    margin-bottom: 3rem; }
  .single-products .elements .element {
    min-width: 6.625rem; }
    @media (max-width: 34rem) {
      .single-products .elements .element {
        min-width: 5.625rem; } }
  .single-products .indications .crop {
    min-width: 5.625rem; }
    @media (max-width: 34rem) {
      .single-products .indications .crop {
        min-width: 5rem; } }
  .single-products .indications .dosage {
    min-width: 10.875rem; }
    @media (max-width: 34rem) {
      .single-products .indications .dosage {
        min-width: 9.625rem; } }
  .single-products .indications tbody .crop {
    background-color: rgba(255, 255, 255, 0.5);
    color: #005640;
    font-weight: 600; }
  .single-products .indications__ps {
    display: block;
    padding: 1em;
    color: #848484;
    line-height: 1.5em; }
  .single-products .product__image {
    margin-bottom: 2rem; }
  .single-products .sizes__title {
    width: 100%;
    background-color: #76BC21;
    color: #005640;
    text-align: center;
    padding: .5rem 1rem;
    margin-bottom: 0;
    border-radius: 0.375rem 0.375rem 0 0; }
  .single-products .sizes__wrapper {
    padding: 2rem;
    background-color: #F8F8F8;
    border-radius: 0 0 0.375rem 0.375rem; }
  .single-products .sizes__loop {
    lost-flex-container: row; }
  .single-products .sizes__loop .size {
    lost-column: 1/3 3 2rem;
    text-align: center; }
  .single-products .size__caption {
    margin-top: 1rem;
    color: #848484;
    font-weight: 600; }
  .single-products .quotation.section {
    border-top: 4rem solid white;
    border-bottom: 4rem solid white; }

.post-type-archive-crops,
.page.culturas,
.single-crops {
  background-color: white; }
  .post-type-archive-crops .crops.section,
  .page.culturas .crops.section,
  .single-crops .crops.section {
    padding-top: 12rem;
    padding-bottom: 4rem;
    background-color: #005640;
    position: relative; }
    .post-type-archive-crops .crops.section:after,
    .page.culturas .crops.section:after,
    .single-crops .crops.section:after {
      content: "";
      width: 0;
      height: 0;
      border-left: 8rem solid transparent;
      border-right: 8rem solid transparent;
      border-top: 4rem solid #005640;
      display: block;
      position: absolute;
      bottom: -4rem;
      left: 50%;
      margin-left: -8rem; }
    @media (min-width: 56.25rem) {
      .post-type-archive-crops .crops.section,
      .page.culturas .crops.section,
      .single-crops .crops.section {
        padding-top: 16rem;
        padding-bottom: 8rem; } }
    @media (min-width: 103.75rem) {
      .post-type-archive-crops .crops.section,
      .page.culturas .crops.section,
      .single-crops .crops.section {
        padding-top: 18rem; } }
  .post-type-archive-crops .crops.section h1,
  .post-type-archive-crops .crops.section p,
  .page.culturas .crops.section h1,
  .page.culturas .crops.section p,
  .single-crops .crops.section h1,
  .single-crops .crops.section p {
    width: 100%;
    text-align: center;
    color: white; }
  .post-type-archive-crops .menu,
  .page.culturas .menu,
  .single-crops .menu {
    list-style: none; }
  .post-type-archive-crops .crops__menu,
  .page.culturas .crops__menu,
  .single-crops .crops__menu {
    width: 100%;
    margin-top: 2rem; }
  .post-type-archive-crops .crops__menu,
  .page.culturas .crops__menu,
  .single-crops .crops__menu {
    width: 100%;
    text-align: center; }
  .post-type-archive-crops .crops__menu .menu__item,
  .page.culturas .crops__menu .menu__item,
  .single-crops .crops__menu .menu__item {
    margin-left: 1rem;
    margin-right: 1rem;
    display: inline-block; }
  .post-type-archive-crops .phases.section,
  .page.culturas .phases.section,
  .single-crops .phases.section {
    padding-top: 8rem;
    padding-bottom: 4rem;
    background-color: white; }
  .post-type-archive-crops .phases__content,
  .page.culturas .phases__content,
  .single-crops .phases__content {
    lost-flex-container: row; }
    @media (max-width: 34rem) {
      .post-type-archive-crops .phases__content,
      .page.culturas .phases__content,
      .single-crops .phases__content {
        max-width: 22.5rem; } }
    @media (min-width: 35rem) {
      .post-type-archive-crops .phases__content,
      .page.culturas .phases__content,
      .single-crops .phases__content {
        max-width: 42rem; } }
    @media (min-width: 56.25rem) {
      .post-type-archive-crops .phases__content,
      .page.culturas .phases__content,
      .single-crops .phases__content {
        max-width: 72rem; } }
    @media (min-width: 103.75rem) {
      .post-type-archive-crops .phases__content,
      .page.culturas .phases__content,
      .single-crops .phases__content {
        max-width: 103.75rem; } }
  .post-type-archive-crops .phases__content .col,
  .page.culturas .phases__content .col,
  .single-crops .phases__content .col {
    width: 100%; }
    @media (min-width: 56.25rem) {
      .post-type-archive-crops .phases__content .col,
      .page.culturas .phases__content .col,
      .single-crops .phases__content .col {
        lost-column: 1/3 3 4rem; } }
  .post-type-archive-crops .phases__content .phase .image,
  .page.culturas .phases__content .phase .image,
  .single-crops .phases__content .phase .image {
    max-width: 6rem;
    display: table;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2rem; }
    @media (min-width: 56.25rem) {
      .post-type-archive-crops .phases__content .phase .image,
      .page.culturas .phases__content .phase .image,
      .single-crops .phases__content .phase .image {
        max-width: 10rem;
        margin-bottom: 0; } }
    @media (min-width: 80rem) {
      .post-type-archive-crops .phases__content .phase .image,
      .page.culturas .phases__content .phase .image,
      .single-crops .phases__content .phase .image {
        max-width: 14rem; } }
  .post-type-archive-crops .phases__content .phase .image__caption,
  .page.culturas .phases__content .phase .image__caption,
  .single-crops .phases__content .phase .image__caption {
    margin-top: 2rem;
    width: 100%;
    text-align: center;
    color: #848484; }
  .post-type-archive-crops .phases__content .phase__info,
  .page.culturas .phases__content .phase__info,
  .single-crops .phases__content .phase__info {
    lost-align: center;
    flex-direction: column; }
  .post-type-archive-crops .phases__content .phase__info .phase__crop,
  .page.culturas .phases__content .phase__info .phase__crop,
  .single-crops .phases__content .phase__info .phase__crop {
    font-weight: normal;
    font-size: 1.5rem; }
  .post-type-archive-crops .phases__content .phase__info .phase,
  .page.culturas .phases__content .phase__info .phase,
  .single-crops .phases__content .phase__info .phase {
    font-size: 4rem;
    font-weight: normal;
    color: #76BC21;
    margin-bottom: 1rem;
    line-height: 1; }
  .post-type-archive-crops .phases__content .phase__info .indications,
  .page.culturas .phases__content .phase__info .indications,
  .single-crops .phases__content .phase__info .indications {
    list-style: none; }
  .post-type-archive-crops .phases__content .phase__info .indication,
  .page.culturas .phases__content .phase__info .indication,
  .single-crops .phases__content .phase__info .indication {
    margin-bottom: .5em;
    text-align: center; }
    .post-type-archive-crops .phases__content .phase__info .indication:last-child,
    .page.culturas .phases__content .phase__info .indication:last-child,
    .single-crops .phases__content .phase__info .indication:last-child {
      margin-bottom: 0; }
  .post-type-archive-crops .phases__content .phase__info .indication .product,
  .page.culturas .phases__content .phase__info .indication .product,
  .single-crops .phases__content .phase__info .indication .product {
    font-size: 1.5rem;
    color: #76BC21;
    text-decoration: none; }
    .post-type-archive-crops .phases__content .phase__info .indication .product:hover,
    .page.culturas .phases__content .phase__info .indication .product:hover,
    .single-crops .phases__content .phase__info .indication .product:hover {
      color: #005640; }
  .post-type-archive-crops .phases__menu,
  .page.culturas .phases__menu,
  .single-crops .phases__menu {
    margin-top: 4rem;
    flex-flow: columnn wrap; }
  .post-type-archive-crops .phases__menu .menu__title,
  .post-type-archive-crops .phases__menu .glide__bullets,
  .page.culturas .phases__menu .menu__title,
  .page.culturas .phases__menu .glide__bullets,
  .single-crops .phases__menu .menu__title,
  .single-crops .phases__menu .glide__bullets {
    width: 100%;
    text-align: center; }
  .post-type-archive-crops .phases__menu .menu__title,
  .page.culturas .phases__menu .menu__title,
  .single-crops .phases__menu .menu__title {
    margin-bottom: 2rem; }
  .post-type-archive-crops .phases__menu .glide__bullet,
  .page.culturas .phases__menu .glide__bullet,
  .single-crops .phases__menu .glide__bullet {
    display: inline-block;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem; }

.quotation-form {
  background-color: white;
  padding-top: 12rem;
  padding-bottom: 4rem; }
  @media (min-width: 56.25rem) {
    .quotation-form {
      padding-top: 16rem;
      padding-bottom: 8rem; } }
  .quotation-form .col {
    margin-bottom: 2rem;
    display: flex;
    flex-flow: row wrap;
    align-content: center; }
    .quotation-form .col:last-child {
      margin-bottom: 0; }
    @media (min-width: 56.25rem) {
      .quotation-form .col {
        lost-column: 1/2 2 4rem; } }
  .quotation-form .form {
    width: 100%; }
  .quotation-form .field__input {
    border: 0.0625rem solid #848484; }

.single-post {
  background-color: white; }
  .single-post .post.section {
    padding-top: 12rem;
    padding-bottom: 4rem;
    background-color: white; }
    @media (min-width: 56.25rem) {
      .single-post .post.section {
        padding-top: 16rem;
        padding-bottom: 8rem; } }
  .single-post .post__title {
    margin-bottom: 2rem; }
  .single-post .content-wrapper {
    display: block;
    width: 100%;
    max-width: 50rem;
    margin-left: auto;
    margin-right: auto; }

.blog .blog.section,
.page-blog .blog.section {
  padding-top: 12rem;
  padding-bottom: 4rem; }
  @media (min-width: 56.25rem) {
    .blog .blog.section,
    .page-blog .blog.section {
      padding-top: 16rem;
      padding-bottom: 8rem; } }

.blog .blog__header,
.page-blog .blog__header {
  width: 100%;
  margin-bottom: 2rem;
  text-align: center; }
  @media (min-width: 56.25rem) {
    .blog .blog__header,
    .page-blog .blog__header {
      margin-bottom: 4rem; } }

.blog .blog__title,
.page-blog .blog__title {
  color: #76BC21; }

.blog .blog__posts,
.page-blog .blog__posts {
  lost-flex-container: row;
  justify-content: center;
  width: 100%; }

.blog .article,
.page-blog .article {
  margin-bottom: 2rem; }
  @media (min-width: 56.25rem) {
    .blog .article,
    .page-blog .article {
      lost-column: 1/3 3 2rem; } }

.page.contato .hero {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #005640;
  color: white;
  display: flex;
  padding-top: 12rem;
  padding-bottom: 4rem; }
  @media (min-width: 56.25rem) {
    .page.contato .hero {
      padding-top: 16rem;
      padding-bottom: 8rem; } }

.page.contato .hero .col {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  margin-bottom: 2rem; }
  .page.contato .hero .col:last-child {
    margin-bottom: 0; }
  @media (min-width: 56.25rem) {
    .page.contato .hero .col {
      lost-column: 1/2 2 4rem; } }

.page.contato .hero .content {
  padding-bottom: 4rem; }

.page.contato .hero p {
  color: white; }

.page.contato .form {
  width: 100%; }
