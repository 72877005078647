body {
  font-family: "Gibson", sans-serif;
  font-size: 1em;
  font-weight: normal;
  color: $green;
  background-color: $light-gray;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// Selection
*::selection {
  background: $green;
  color: white;
}

// Don't scroll when side menu is active
.no-scroll {
  overflow: hidden;
}

// Typography
p {
  line-height: 1.5em;
  margin-bottom: 1.5em;
  color: $gray;

  &:last-child {
    margin-bottom: 0;
  }
}

p a {
  font-weight: 600;
  color: $green;

  &:hover {
    color: $light-green;
  }
}

strong {
  font-weight: 600;
}

dt {
  font-weight: 600;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  font-weight: 600;

  a {
    text-decoration: none;

    &:hover {
      color: $light-green;
    }
  }
}

ul,
ol {
  list-style-position: inside;
  line-height: 1.5em;
}

// Links and menus
a {
  color: $green;
  transition: color 0.2s;
}

a.link {
  font-weight: 600;
  text-decoration: none;
  line-height: 1rem;
}

nav ul {
  list-style: none;
}

nav a {
  text-decoration: none;
}

button {
  outline: none;
}

address {
  font-style: normal;
  line-height: 1.5em;
}

// Image
img {
  height: auto;
}

// Structure
.app {
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.content {
  min-height: 26rem;

  @include for-tablet-portrait-up {
    min-height: 36rem;
  }
}

.center {
  width: 100%;
  lost-utility: clearfix;

  @include for-phone-only {
    lost-center: 22.5rem;
  }

  @include for-tablet-portrait-up {
    lost-center: 42rem;
  }

  @include for-tablet-landscape-up {
    lost-center: 72rem;
  }

  @include for-big-desktop-up {
    lost-center: 103.75rem;
  }
}
