.page.contato {
  .hero {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-color: $green;
    color: white;
    display: flex;
    padding-top: $margin * 6;
    padding-bottom: $margin * 2;

    @include for-tablet-landscape-up {
      padding-top: $margin * 8;
      padding-bottom: $margin * 4;
    }
  }

  .hero .col {
    width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
    margin-bottom: $margin;

    &:last-child {
      margin-bottom: 0;
    }

    @include for-tablet-landscape-up {
      lost-column: 1/2 2 $margin * 2;
    }
  }

  .hero .content {
    padding-bottom: $margin * 2;
  }

  .hero p {
    color: white;
  }

  .form {
    width: 100%;
  }
}
