.quotation.section {
  background-color: $light-green;
  padding-top: $margin * 2;
  padding-bottom: $margin * 2;

  @include for-tablet-landscape-up {
    height: 36vw;
    position: relative;
    border-top: $margin * 2 solid $light-gray;
    border-bottom: $margin * 2 solid $light-gray;
    padding-top: 0;
    padding-bottom: 0;
  }
}

.single-post .quotation.section {
  border-top: $margin * 2 solid white;
  border-bottom: $margin * 2 solid white;
}

.page.em-breve .quotation.section {
  margin-top: $margin * 2;
  margin-bottom: $margin * 2;
}

.quotation.section .center {
  height: 100%;
}

.quotation.section .section__title {
  color: $green;
}

.quotation.section .quotation__title {
  font-size: 2.25rem;
  font-weight: 600;
  color: $green;
}

.quotation.section .col {
  margin-bottom: $margin;

  &:last-child {
    margin-bottom: 0;
  }

  @include for-tablet-landscape-up {
    lost-column: 1/2 2 0;
  }
}

.quotation.section .quotation__header {

  @include for-tablet-landscape-up {
    lost-move: 1/2;
    padding: $margin * 2;
    position: relative;
    display: flex;
    flex-flow: row wrap;
    align-content: center;
  }
}

.quotation.section .quotation__title {
  color: white;
}

.quotation.section .quotation__text {
  color: white;
}

.quotation.section .quotation__image {
  width: 100%;
  height: 64vw;

  @include for-tablet-landscape-up {
    lost-move: -1/2;
    width: 50vw;
    height: 36vw;
    position: absolute;
    top: -$margin * 2;
    left: 0;
  }
}
