.header {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2;
  padding-top: $margin;
  padding-bottom: $margin;
}

.header__logo {

  @include for-phone-only {
    width: 70%;
  }

  @include for-tablet-portrait-up {
    lost-column: 1/2 2 $margin;
  }

  @include for-tablet-landscape-up {
    lost-column: 1/3 2 $margin;
  }
}

.header__logo .wrapper {
  width: 100%;
  max-width: 20rem;
}

.header__logo .logo {
  width: 100%;
}

.header__logo .coopavel {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 5%;
}

.header__nav {
  text-align: right;

  @include for-tablet-portrait-up {
    lost-column: 1/2 2 $margin;
  }

  @include for-tablet-landscape-up {
    lost-column: 2/3 2 $margin;
  }
}

.header__nav .menu {
  margin-top: 3.5em;
}

.header__nav .menu>.menu-item {
  display: inline-block;
  margin-left: $margin;
  padding-bottom: .5em;
}

.header__nav a {
  font-size: 1.5rem;
  font-weight: 600;
  color: $green;

  &:hover {
    color: $light-green;
  }
}

.page.home .header__nav a,
.page.sobre .header__nav a,
.post-type-archive-crops .header__nav a,
.single-crops .header__nav a,
.page.culturas .header__nav a,
.page.contato .header__nav a {
  color: white;
}

.header__nav .menu-item-has-children {
  position: relative;
}

.header__nav .menu-item-has-children>a::after {
  content: $icon-down;
  font-family: "#{$icon-font-family}";
  line-height: 0;
  color: $light-green;
  margin-left: .25em;
  position: relative;
  top: .085em;
  transition: color .2s;
}

.header__nav .sub-menu {
  background-color: white;
  position: absolute;
  top: 2em;
  right: 0;
  text-align: left;
  padding-top: .5em;
  padding-bottom: .5em;
  visibility: hidden;
  opacity: 0;
  transition: opacity .2s;
  border-radius: $radius 0 $radius $radius;
  box-shadow: 0 .25rem .75rem rgba($color: #000, $alpha: .2)
}

.header__nav .menu-item-has-children:hover .sub-menu {
  visibility: visible;
  opacity: 1;
}

.header__nav .menu-item-has-children:hover>a::after {
  color: white;
}

.header__nav .sub-menu li {
  display: block;
  padding-bottom: 0;

  &:last-child {
    margin-bottom: 0;
  }

  @include for-desktop-up {
    margin-left: 0;
  }
}

.header__nav .sub-menu a {
  background-color: white;
  color: $gray;
  white-space: nowrap;
  padding: .25em 1em;
  display: block;
  margin-top: 0;

  &:hover {
    color: $light-green;
  }
}
